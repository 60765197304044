const getUpdatedCellHtml = (htmlString, locatorId) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const tdElements = tempElement.querySelectorAll('td');

  for (const td of tdElements) {
    const firstChild = td.firstElementChild;

    if (firstChild.getAttribute('data-locator') === locatorId) {
      return td;
    }
  }

  return null;
};

export default getUpdatedCellHtml;
