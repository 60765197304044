/*
  Debugging:

  The correct replacement of "strToReplace" in the "values" string (beginning at line 32)
  is extremely crucial for the translation of the updated HTML into a block.
  If this doesn't happen, a block can be saved with the wrong props ("font-family"
  having the value of "color", for example), making editing the template impossible.

  To prevent this, make sure the updated HTML, or "content", is IDENTICAL to the
  block's "template", with the only expection being the values in place of the
  variables. This can be done in a variety of ways:

  1. Ensuring that all style attributes that can be edited in the builder have a
  template variable + prop pair in the block definition (data/blocks.js);
  2. Ensuring that any editor-specific attributes, such as "contentEditable" or
  "draggable", are cleared out in the HTML sanitizer before saving
  (TemplateBuilder/index.vue > prepareTemplate).
*/

/**
 * Creates an object for a block by extracting the values found in the updated
 * HTML content.
*/
const getBlockProps = (content, template) => {
  let keys = [];
  let values = content;

  // Create a list with the substrings that include the token symbol ('{{', '}}')
  const templateTokens = template.split('}}');

  // Replace substrings of "template" in "values" to extract the updated HTML values
  templateTokens.forEach((str, index) => {
    let strToReplace = str;

    if (str.includes('{{')) strToReplace = str.split('{{')[0];

    if (index === 0) {
      values = values.replace(strToReplace, '');
    } if (index < template.split('}}').length - 1) {
      keys.push(str.split('{{')[1]);

      // Join values
      values = values.replace(strToReplace, '|');
    } else {
      // Remove substring
      values = values.replace(strToReplace, '');
    }
  });

  // Create array from collected values
  values = values.split('|');

  // Finally, create props object
  let props = {};

  for (const [i, key] of keys.entries()) {
    const keyName = key.split('.')[0];
    const propertyName = key.split('.')[1];

    let value = values[i];

    if (Object.prototype.hasOwnProperty.call(props, keyName)) {
      props[keyName] = { ...props[keyName], [propertyName]: value };
    } else {
      props[keyName] = { [propertyName]: value }
    }
  }

  return props;
};

export default getBlockProps;
