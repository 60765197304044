// Helpers
import toggleFormatTextbox from '../composables/toggle-format-textbox';

/* Detect component */
const isButtonText = (target) => {
  if (target.tagName !== 'SPAN') return false;
  const parentElement = target.parentElement;
  return parentElement.tagName === 'A';
};

const isPlainLinkText = (target) => {
  if (target.tagName !== 'A') return false;

  const hasImgOrSpan = Array.from(target.childNodes).some((childNode) => {
    return (
      childNode.nodeType === Node.ELEMENT_NODE
      && ['IMG', 'SPAN'].includes(childNode.tagName)
    );
  });

  return !hasImgOrSpan;
};

/* Event Listeners */
const handleKeyDown = (e) => {
  // TODO: Fix listener accumulating each time setupEditable is called
  toggleFormatTextbox(false, 600);

  // Prevent the default behavior of certain keys
  if (e.key === 'Tab') {
    e.preventDefault();
  } else if (e.key === 'Enter') {
    e.preventDefault();

    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    range.deleteContents();
    const brNode = document.createElement('br');
    range.insertNode(brNode);
    range.setStartBefore(brNode);
    range.collapse(false);
  }
}

// Handle paste event
const handlePaste = (e) => {
  // Prevent the default paste behavior
  e.preventDefault();

  // Get the pasted text from the event
  const clipboardData = e.clipboardData;
  const pastedText = clipboardData.getData('text/plain');

  // Insert the sanitized text at the current cursor position
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  range.deleteContents();
  range.insertNode(document.createTextNode(pastedText));
}

const setupContentEditable = (target) => {
  const allowEditTags = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'STRONG', 'EM'];

  const allowEdit = (
    allowEditTags.includes(target.tagName)
    || isButtonText(target)
    || isPlainLinkText(target)
  );

  if (allowEdit) {
    toggleFormatTextbox(false, 600);

    target.setAttribute('contentEditable', 'true');

    // Remove previous event listeners for keydown and paste events
    target.removeEventListener('keydown', handleKeyDown);
    target.removeEventListener('paste', handlePaste);

    // Add event listeners for keydown and paste events
    target.addEventListener('keydown', handleKeyDown);
    target.addEventListener('paste', handlePaste);
  }
};

export default setupContentEditable;
