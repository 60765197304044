const componentToHex = (c) => {
  const hex = c.toString(16);

  return hex.length === 1 ? '0' + hex : hex;
}

const rgbToHex = (str) => {
  if (str.includes('#')) return str;

  let rgb = str;

  if (rgb.includes('rgb(')) {
    rgb = rgb.replace('rgb(', '');
  }

  if (rgb.includes(')')) {
    rgb = rgb.replace(')', '');
  }

  let r = rgb.split(',')[0], g = rgb.split(',')[1], b = rgb.split(',')[2];

  const redHex = componentToHex(Math.min(Math.max(0, r), 255));
  const greenHex = componentToHex(Math.min(Math.max(0, g), 255));
  const blueHex = componentToHex(Math.min(Math.max(0, b), 255));

  return '#' + redHex + greenHex + blueHex;
}

export default rgbToHex;
