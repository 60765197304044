// Editor Elements
const deleteCellBtn = '<td class="template-canvas__row-delete"><button type="button" name="button" class="vs-component vs-button template-canvas__row-delete vs-button-danger vs-button-filled includeIcon includeIconOnly small"><span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span><i class="vs-icon notranslate icon-scale vs-button--icon material-icons null" style="order: 0; margin-right: 0px; margin-left: 0px;">delete</i><!----><span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span></button></td>';
const deleteCellBtn2 = '<td class="template-canvas__row-delete"><!----></td>';

const resizeBtnBL = '<button id="resize-left-bottom-btn" class="resize-btn" style="bottom: -8px; left: -8px; cursor: nesw-resize;"></button>';
const resizeBtnTL = '<button id="resize-left-top-btn" class="resize-btn" style="top: -8px; left: -8px; cursor: nwse-resize;"></button>';
const resiseBtnRB = '<button id="resize-right-bottom-btn" class="resize-btn" style="bottom: -8px; right: -8px; cursor: nwse-resize;"></button>';
const resizeBtnRT = '<button id="resize-right-top-btn" class="resize-btn" style="top: -8px; right: -8px; cursor: nesw-resize;"></button>';

// Helpers
import rgbToHex from '@/helpers/rgb-to-hex';

const sanitizeHtml = (html) => {
  const contentEditableF = ' contenteditable="false"';
  const contentEditableT = ' contenteditable="true"';
  const draggableF = ' draggable="false"';
  const draggableT = ' draggable="true"';
  const cssClassCol = ' class="template-canvas__col"';
  const cssClassRow = ' class="template-canvas__row"';
  const cssClassFormatting = ' class="element-selected formatting"';
  const cssClassSelected = ' class="element-selected"';
  const emptyClassList = ' class=""';
  const hrefUndefined = ' href="undefined"';
  const locator = /\s?data-locator="[^"]*"/g;
  const rgbColorRegex = /rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g;
  const srcUndefined = ' src="undefined"';
  const undefinedStr = /undefined/gi;

  return html
    .replaceAll(deleteCellBtn, '')
    .replaceAll(deleteCellBtn2, '')
    .replaceAll(resizeBtnBL, '')
    .replaceAll(resizeBtnTL, '')
    .replaceAll(resiseBtnRB, '')
    .replaceAll(resizeBtnRT, '')
    .replaceAll(contentEditableF, '')
    .replaceAll(contentEditableT, '')
    .replaceAll(draggableF, '')
    .replaceAll(draggableT, '')
    .replaceAll(cssClassCol, '')
    .replaceAll(cssClassRow, '')
    .replaceAll(cssClassFormatting, '')
    .replaceAll(cssClassSelected, '')
    .replaceAll(emptyClassList, '')
    .replaceAll(hrefUndefined, '')
    .replaceAll(locator, '')
    .replaceAll(rgbColorRegex, (match) => rgbToHex(match))
    .replaceAll(srcUndefined, '')
    .replaceAll(undefinedStr, '');
};

export default sanitizeHtml;
