<template>
  <div
    v-show="tooltipVisible"
    class="template-canvas__tooltip"
    :style="{
      left: tooltipPosition.x,
      top: tooltipPosition.y,
    }"
  >
    <!-- Toolbar -->
    <div class="template-canvas__tooltip--toolbar">
      <!-- Selects -->
      <div
        v-if="showFontOptions"
        :style="{
          alignItems: 'center',
          display: 'flex',
          gap: '0.25rem',
          justifyContent: 'center',
        }"
      >
        <vs-select
          v-model="fontFamilyModel"
          class="inset--c-vs-select"
        >
          <vs-select-item
            v-for="option in fontFamilyOptions"
            :key="option.text"
            :text="option.text"
            :value="option.value"
            :style="{ fontFamily: option.value }"
          />
        </vs-select>

        <vs-select
          v-model="fontSizeModel"
          class="inset--c-vs-select small"
        >
          <vs-select-item
            v-for="option in fontSizeOptions"
            :key="option.text"
            :text="option.text"
            :value="option.value"
          />
        </vs-select>
      </div>

      <!-- Controls -->
      <div
        v-for="btn in toolbarControls"
        :key="btn.id"
      >
        <vs-button
          class="control--c-vs-button"
          style="height: 38px; width: 48px;"
          @click="() => toggleControl(btn.id)"
        >
          <div class="d-flex align-items-center">
            <span
              class="material-icons-outlined"
              style="font-size: 18px;"
            >
              {{ btn.icon }}
            </span>
            <span
              class="material-icons-outlined"
              style="font-size: 14px;"
            >
              {{ control === btn.id ? 'arrow_drop_up' : 'arrow_drop_down' }}
            </span>
          </div>
        </vs-button>
      </div>

      <!-- Switches -->
      <vs-button
        v-for="btn in toolbarSwitches"
        :key="btn.id"
        :icon="btn.icon"
        class="control--c-vs-button"
        @click="() => toggleSwitch(btn.value)"
      />

      <!-- Toggles -->
      <vs-button
        v-for="btn in toolbarToggles"
        :key="btn.id"
        :icon="btn.values.find((v) => v.value === inputs[btn.property]).icon"
        class="control--c-vs-button"
        @click="() => toggleStyle(btn.property, btn.values)"
      />
    </div>

    <!-- Change Text Color -->
    <div class="template-canvas__tooltip--controls">
      <template v-if="control === 'background-color'">
        <ColorPicker
          :value="inputs.bgColor"
          style-property="backgroundColor"
          @update-inputs="updateInputs"
        />
      </template>
      <template v-else-if="control === 'color'">
        <ColorPicker
          :value="inputs.textColor"
          style-property="textColor"
          @update-inputs="updateInputs"
        />
      </template>
      <div
        v-else-if="control === 'link' || control === 'img-link'"
        class="controls--col"
      >
        <label>
          URL:
          <input
            v-model="hrefModel"
            @keydown="confirmAttrChange"
          >
        </label>
      </div>
      <div
        v-else-if="control === 'image'"
        class="controls--col"
      >
        <div :style="{ alignItems: 'center', display: 'flex', gap: '0.15rem' }">
          <input
            id="image-url-input-radio"
            v-model="imageSource"
            type="radio"
            value="url"
          >

          <label for="image-url-input-radio">URL:</label>

          <input
            v-model="srcModel"
            :disabled="imageSource === 'upload'"
          >
        </div>

        <div :style="{ alignItems: 'center', display: 'flex', gap: '0.15rem', marginTop: '0.5rem' }">
          <input
            id="file-input-radio"
            v-model="imageSource"
            type="radio"
            value="upload"
          >

          <label for="file-input-radio">Upload:</label>

          <input
            id="tooltip-file-input"
            accept=".jpg,.jpeg,.png"
            class="small"
            :disabled="imageSource === 'url'"
            type="file"
            @change="uploadFile"
          >
        </div>

        <vs-divider />

        <div :style="{ alignItems: 'center', display: 'flex', gap: '0.5rem' }">
          <label>
            Largura:
            <input v-model="imageWidthModel">
          </label>

          <label>
            Preencher:
            <input
              v-model="imageWidthFullModel"
              :style="{ marginLeft: '0.15rem', marginTop: 'auto' }"
              type="checkbox"
            >
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Components */
import ColorPicker from './ColorPicker.vue';

/* Helpers */
import {
  formatTextNodes,
  clearTextNodesFormatting,
  formatSelection,
  clearSelectionFormatting,
} from './helpers/format-text';

export default {
  name: 'TooltipToolbar',
  components: { ColorPicker },
  props: {
    control: {
      type: String,
      required: true,
    },
    toolbar: {
      type: Array,
      required: true,
    },
    inputs: {
      type: Object,
      required: true,
    },
    tooltipPosition: {
      type: Object,
      required: true,
    },
    tooltipVisible: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    initialImageWidth: '',
    imageSource: 'url',
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    /* Toolbar */
    fontFamilyOptions() {
      return [
        { text: 'Arial', value: 'Arial, sans-serif' },
        { text: 'Verdana', value: 'Verdana, sans-serif' },
        { text: 'Tahoma', value: 'Tahoma, sans-serif' },
        { text: 'Trebuchet-MS', value: 'Trebuchet MS, sans-serif' },
        { text: 'Times-New-Roman', value: 'Times New Roman, serif' },
        { text: 'Georgia', value: 'Georgia, serif' },
        { text: 'Garamond', value: 'Garamond, serif' },
        { text: 'Courier-New', value: 'Courier New, monospace' },
        { text: 'Brush-Script-MT', value: 'Brush Script MT, cursive' },
      ];
    },
    fontSizeOptions() {
      return [
        { text: '12', value: '12px', lineHeight: '16.8px' },
        { text: '14', value: '14px', lineHeight: '19.6px' },
        { text: '16', value: '16px', lineHeight: '22.4px' },
        { text: '18', value: '18px', lineHeight: '25.2px' },
        { text: '20', value: '20px', lineHeight: '28px' },
        { text: '24', value: '24px', lineHeight: '33.6px' },
        { text: '28', value: '28px', lineHeight: '39.2px' },
        { text: '30', value: '30px', lineHeight: '42px' },
        { text: '32', value: '32px', lineHeight: '44.8px' },
        { text: '36', value: '36px', lineHeight: '50.4px' },
        { text: '42', value: '42px', lineHeight: '58.8px' },
        { text: '48', value: '48px', lineHeight: '67.2px' },
        { text: '56', value: '56px', lineHeight: '78.4px' },
        { text: '64', value: '64px', lineHeight: '89.6px' },
      ];
    },
    showFontOptions() {
      return this.toolbar.find((btn) => btn.id === 'font-options');
    },
    toolbarControls() {
      return this.toolbar.filter((btn) => btn.type === 'control');
    },
    toolbarSwitches() {
      return this.toolbar.filter((btn) => btn.type === 'switch');
    },
    toolbarToggles() {
      return this.toolbar.filter((btn) => btn.type === 'toggle');
    },
    /* Models */
    fontFamilyModel: {
      get() { return this.inputs.fontFamily },
      set(value) { this.updateInputs('fontFamily', value) },
    },
    fontSizeModel: {
      get() { return this.inputs.fontSize },
      set(value) { this.updateInputs('fontSize', value) },
    },
    hrefModel: {
      get() { return this.inputs.href },
      set(value) { this.updateInputs('href', value) },
    },
    imageWidthModel: {
      get() { return this.inputs.imageWidth },
      set(value) { this.updateInputs('imageWidth', value) },
    },
    imageWidthFullModel: {
      get() { return this.inputs.imageWidth === '600px' },
      set() {
        if (this.inputs.imageWidth === '600px') {
          this.updateInputs('imageWidth', this.initialImageWidth);
        } else {
          this.updateInputs('imageWidth', '600px');
        }
      },
    },
    srcModel: {
      get() { return this.inputs.src },
      set(value) { this.updateInputs('src', value) },
    },
  },
  watch: {
    inputs(value) {
      if (value.imageWidth) {
        if (value.imageWidth !== '600px') {
          console.log('I WAS HERE', value.imageWidth);
          this.initialImageWidth = value.imageWidth;
        }

        this.changeElementStyle('width', value.imageWidth);

        // Set image size for Windows Outlook
        const widthNoUnit = `${String(value.imageWidth).replace('px', '')}`;
        this.changeElementAttr('width', widthNoUnit);
      }

      if (value.href) this.changeElementAttr('href', value.href);
      if (value.src) this.changeElementAttr('src', value.src);
      if (value.backgroundColor) this.changeElementStyle('backgroundColor', value.backgroundColor);
      if (value.fontFamily) this.changeElementStyle('fontFamily', value.fontFamily);

      if (value.fontSize) {
        const { lineHeight } = this.fontSizeOptions.find((item) => item.value === value.fontSize);

        this.changeElementStyle('fontSize', value.fontSize);
        this.changeElementStyle('lineHeight', lineHeight);
      }

      if (value.textAlign) this.changeElementStyle('textAlign', value.textAlign);
      if (value.textColor) this.changeElementStyle('color', value.textColor);
      if (value.width) this.changeElementStyle('width', value.width);
    },
  },
  methods: {
    /* Image Upload */
    uploadFile() {
      this.$store.dispatch('set_isLoadingActive', true);

      const fileInput = document.getElementById('tooltip-file-input');
      const file = fileInput.files[0];

      const formData = new FormData();
      formData.append('image', file);

      const url = `${process.env.VUE_APP_BASEDOMAIN}/api/files/${this.ownerId}/3`;

      fetch(url, { method: 'POST', body: formData })
        .then((resp) => {
          if (resp.ok) return resp.text();
        })
        .then((resp) => {
          const data = JSON.parse(resp).data;

          const fallbackUrl = `http://config.fanbase.com.br/user/fanbase/${this.ownerId}/files/${file.name}`;

          if (data.url && data.url.length > 0) {
            this.updateInputs('src', data.url);
          } else {
            this.updateInputs('src', fallbackUrl);
          }
        })
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Event Handlers */
    confirmAttrChange(e) {
      if (e.key === 'Enter') this.$emit('hide-tooltip');
    },
    /* Helpers */
    changeElementAttr(attr, value) {
      const selected = document.querySelector('.element-selected');

      // Prevent click during edit mode by adding "data-" prefix to href attribute
      if (attr === 'href') {
        if (this.control === 'img-link') {
          selected.parentElement.setAttribute(`data-${attr}`, value);
        } else if (this.control === 'link') {
          selected.setAttribute(`data-${attr}`, value);
        }
      } else {
        selected.setAttribute(attr, value);
      }
    },
    changeElementStyle(property, value) {
      const selected = document.querySelector('.element-selected');
      if (selected.style !== null) selected.style[property] = value;
    },
    toggleControl(value) {
      if (this.control === value) {
        this.$emit('set-control', '');
      } else {
        this.$emit('set-control', value);
      }
    },
    toggleStyle(property, values) {
      this.toggleControl('');

      if (this.inputs[property] === values[0].value) {
        this.updateInputs(property, values[1].value);
      } else if (this.inputs[property] === values[1].value) {
        if (values[2]) {
          this.updateInputs(property, values[2].value);
        } else {
          this.updateInputs(property, values[0].value);
        }
      } else if (this.inputs[property] === values[2].value) {
        this.updateInputs(property, values[0].value);
      }
    },
    toggleSwitch(value) {
      this.toggleControl('');

      if (['bold', 'italic'].includes(value)) {
        this.toggleTextFormat(value);
      }
    },
    toggleTextFormat(format) {
      const formatElement = format === 'bold' ? 'strong' : 'em';
      const selection = window.getSelection();

      if (!this.inputs[format]) {
        this.updateInputs(format, true);

        if (selection && !selection.isCollapsed) {
          formatSelection(selection.getRangeAt(0), formatElement);
        } else {
          clearTextNodesFormatting(formatElement);
          formatTextNodes(formatElement);
        }
      } else {
        this.updateInputs(format, false);

        if (selection && !selection.isCollapsed) {
          clearSelectionFormatting(selection.getRangeAt(0), formatElement);
        } else {
          clearTextNodesFormatting(formatElement);
        }
      }
    },
    updateInputs(key, value) {
      let updated = { ...this.inputs };
      updated[key] = value;
      this.$emit('update-inputs', updated);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-canvas__tooltip {
  align-items: flex-start;
  border: 0;
  border-radius: 0.25rem;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  z-index: 9999;

  .template-canvas__tooltip--toolbar {
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #D8DBDE !important;
    border-radius: 0.25rem;
    color: #0D1216;
    display: flex;
    font-size: 12px;
    gap: 0.25rem;
    justify-content: center;
    padding: 0.25rem;
  }

  .template-canvas__tooltip--controls {
    background-color: white;
    display: flex;
  }
}

.controls--col {
  background-color: white;
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-flow: column nowrap;
  padding: 12px;

  label {
    font-size: 0.9em;
    font-weight: 600;
    margin: 0;
  }
}
</style>

<style>
/* Controls */
/* Custom Button */
.control--c-vs-button.vs-button {
  background-color: white !important;
  color: #0D1216 !important;
}

.control--c-vs-button.vs-button:hover {
  background-color: #f1f3f5 !important;
  box-shadow: none !important;
}

.control--c-vs-button.vs-button .vs-icon {
  color: #0D1216 !important;
  font-size: 19px !important;
}

/* Custom Select */
.inset--c-vs-select.con-select {
  font-size: 1.15em !important;
  margin-left: 0.15rem;
  width: 165px !important;
}

.inset--c-vs-select.con-select.small {
  font-size: 1.15em !important;
  margin-left: 0.15rem;
  width: 65px !important;
}

.inset--c-vs-select .vs-select--input {
  background: transparent !important;
  border: 1px solid #D8DBDE !important;
  border-radius: 4px !important;
  outline: none !important;
}
</style>
