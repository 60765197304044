<!-- eslint-disable vue/no-v-html -->
<template>
  <vs-card class="email-template-builder">
    <header
      slot="header"
      class="email-template-builder__header--compact"
    >
      <div class="d-flex align-items-center">
        <span class="c-input--large text-muted h4 font-weight-normal">
          {{ templateName }}
        </span>
      </div>

      <div class="email-template-builder__header-toolbar">
        <!-- TODO: IMPLEMENT "EVENT" HISTORY - do and undo actions -->
        <!-- <vs-button
          color="gainsboro"
          icon="undo"
          text-color="#444"
        />

        <vs-button
          color="gainsboro"
          icon="redo"
          text-color="#444"
        /> -->
      </div>

      <div class="email-template-builder__header-actions">
        <div class="d-flex align-items-start gap-3">
          <vs-button
            color="gray"
            class="btnx"
            href.prevent
          >
            Visualizar
          </vs-button>

          <vs-dropdown>
            <vs-button
              class="btn-drop"
              color="gray"
              type="filled"
              icon="expand_more"
            />

            <vs-dropdown-menu id="custom-dropdown-menu">
              <vs-dropdown-item class="p-0 b-0">
                <vs-button
                  class="p-2 by-1 w-100 h-100 text-left"
                  type="flat"
                  @click="previewHtml"
                >
                  <i class="float-left mdi mdi-web mr-2" />

                  Visualizar no browser
                </vs-button>
              </vs-dropdown-item>

              <vs-dropdown-item class="p-0 b-0">
                <vs-button
                  class="p-2 by-1 w-100 h-100 text-left"
                  type="flat"
                  @click="downloadHtml"
                >
                  <i class="float-left mdi mdi-download mr-2" />

                  Baixar HTML
                </vs-button>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <vs-button @click="triggerSave">
          {{ mode === 'edit' ? 'Salvar Template' : 'Criar Template' }}
        </vs-button>

        <vs-button
          icon="close"
          size="large"
          type="flat"
          radius
          @click="goToTemplates"
        />
      </div>
    </header>

    <main class="email-template-builder__content">
      <ElementLibrary />

      <TemplateCanvas
        :selected-template="selectedTemplate"
        :save-trigger="saveTrigger"
        @save="handleSave"
      />
    </main>

    <footer slot="footer" />

    <vs-popup
      id="create-template-popup"
      title="Criar Template"
      :active.sync="createPopup"
    >
      <div class="create-template-popup">
        <vs-input
          v-model="templateName"
          class="c-input--large"
          icon="edit"
          icon-after="true"
          maxlength="30"
          placeholder="Nome do Template"
          @focus.stop="templateNameErr = false"
        />

        <vs-alert
          v-if="templateNameErr"
          active="true"
          color="danger"
          :style="{ height: '100%' }"
        >
          O nome do template deve ser diferente dos existentes!
        </vs-alert>

        <vs-button
          :disabled="!validTemplate"
          size="large"
          class="mt-4"
          @click="createTemplate"
        >
          Criar Template
        </vs-button>
      </div>
    </vs-popup>
  </vs-card>
</template>

<script>
/* Components */
import ElementLibrary from './ElementLibrary.vue';
import TemplateCanvas from './TemplateCanvas.vue';

/* Helpers */
import generateHtmlFile from './helpers/generate-html-file';
import getBlockProps from './helpers/get-block-props';
import getUpdatedCellHtml from './helpers/get-updated-cell-html';
import sanitizeHtml from './helpers/sanitize-template-html';

/* Services */
import Template from '@/services/template';

const templateService = new Template();

/* Style */
import './scss/index.scss';

export default {
  name: 'TemplateBuilder',
  components: {
    ElementLibrary,
    TemplateCanvas,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    selectedTemplate: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    /* Template Definition */
    templateName: '',
    /* Create, Update & Preview */
    tableInnerHtml: '',
    templateConfig: {},
    templateNameErr: false,
    /* Popups */
    createPopup: false,
    /* Actions */
    saveTrigger: false,
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    validTemplate() {
      return this.templateName.length > 0;
    },
  },
  mounted() {
    if (this.mode === 'edit') {
      this.templateName = this.selectedTemplate.title;
    }
  },
  methods: {
    /* API */
    createTemplate() {
      this.$store.dispatch('set_isLoadingActive', true);

      this.setTableInnerHtml();

      const htmlContent = generateHtmlFile(this.tableInnerHtml);

      // Create template file
      templateService
        .saveTemplateFile(
          this.ownerId,
          htmlContent,
          this.templateName,
        )
        .then((resp) => {
          // Create template config
          const payload = {
            fileRef: resp.fileId.toString(),
            content: this.templateConfig,
            fileIndexes: [],
            fileType: 1,
          };

          templateService.createTemplate(payload, this.token)
            .then(() => this.goToTemplates())
            .catch(() => this.createErrAlert())
            .finally(() => this.$store.dispatch('set_isLoadingActive', false));
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          if (err.startsWith('Arquivo já existente')) {
            this.templateNameErr = true;
          } else {
            this.createErrAlert();
          }
        });
    },
    updateTemplate() {
      this.$store.dispatch('set_isLoadingActive', true);

      this.setTableInnerHtml();

      const htmlContent = generateHtmlFile(this.tableInnerHtml, true);

      // Update template file with base64 content
      templateService
        .updateTemplateFile(
          this.ownerId,
          this.selectedTemplate.id,
          this.templateName,
          htmlContent,
        )
        .then((resp) => {
          // Update template config
          const payload = {
            fileRef: resp,
            content: this.templateConfig,
            fileId: this.selectedTemplate.fileId,
          };

          templateService.updateTemplate(payload, this.token)
            .then(() => this.goToTemplates())
            .catch(() => this.updateErrAlert())
            .finally(() => this.$store.dispatch('set_isLoadingActive', false));
        })
        .catch(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.createErrAlert();
        });
    },
    /* Helpers */
    downloadHtml() {
      this.setTableInnerHtml();

      const blob = generateHtmlFile(this.tableInnerHtml);

      let downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);

      if (this.templateName.length > 0) {
        downloadLink.download = this.templateName;
      } else {
        downloadLink.download = 'novo_template';
      }

      downloadLink.click();
    },
    getHtmlContent() {
      const table = document.getElementById('template-canvas-table');
      return table.innerHTML;
    },
    handleSave(rows) {
      // Set template config
      this.prepareTemplate(rows);

      const rowsString = JSON.stringify(rows);
      const missingUnsubscribe = !rowsString.includes('{{unsubscribe_url}}');
      const missingWebVersion = !rowsString.includes('{{webversion}}');

      if (missingUnsubscribe) {
        this.invalidDialog('Seu modelo de e-mail não possui um botão de cancelamento (Rodapé). Garanta conformidade adicionando um antes de salvar.');
      } else if (missingWebVersion) {
        this.invalidDialog('Não recomendamos o envio e-mails sem cabeçalho. Garanta conformidade adicionando um antes de salvar.');
      } else {
        if (this.mode === 'create') this.openCreatePopup();
        if (this.mode === 'edit') this.updateTemplate();
      }
    },
    prepareTemplate(rows) {
      const html = this.getHtmlContent();
      let updatedRows = [...rows];

      // Translate rows' HTML content to blocks for the back-end
      updatedRows = updatedRows.map((row) => {
        const cells = row.cells.map((cell) => {
          /* Read "TemplateCanvas.vue" for more details */
          // Replace each cell "content" property with the real edited HTML
          const updatedCellHtml = getUpdatedCellHtml(html, cell.id);
          const updatedCellStyle = sanitizeHtml(updatedCellHtml.style.cssText);

          let translatedCell = {
            id: cell.id,
            colspan: cell.colspan,
            style: updatedCellStyle,
          };

          // Get block definition
          const updatedBlockContent = sanitizeHtml(updatedCellHtml.innerHTML);
          const props = getBlockProps(updatedBlockContent, cell.block.template);

          translatedCell.block = {
            id: cell.block.id,
            props: props,
          };

          return translatedCell;
        });

        return { ...row, cells };
      });

      this.templateConfig = updatedRows;
    },
    setTableInnerHtml() {
      const html = this.getHtmlContent();
      let sanitizedHtml = sanitizeHtml(html);

      // Make links clickable by removing the "data-" prefix from href
      const dataPrefix = /data-/gi;

      sanitizedHtml = sanitizedHtml.replaceAll(dataPrefix, '');

      this.tableInnerHtml = sanitizedHtml;
    },
    triggerSave() {
      this.saveTrigger = true;
      setTimeout(() => (this.saveTrigger = false), 100);
    },
    /* Alerts */
    invalidDialog(message) {
      this.$vs.dialog({
        color: 'danger',
        title: 'Alerta',
        text: message,
        acceptText: 'Fechar',
      });
    },
    createErrAlert() {
      this.$vs.notify({
        title: 'Erro ao criar',
        color: 'danger',
        text: `Não foi possível criar "${this.templateName}".`
      });
    },
    updateErrAlert() {
      this.$vs.notify({
        title: 'Erro ao salvar',
        color: 'danger',
        text: `Não foi possível salvar "${this.templateName}".`
      });
    },
    /* Popups */
    openCreatePopup() {
      this.createPopup = true;
    },
    previewHtml() {
      this.setTableInnerHtml();

      const blob = generateHtmlFile(this.tableInnerHtml);

      const newTab = window.open('', '_blank');
      const reader = new FileReader();

      reader.onloadend = function () {
        newTab.document.write(reader.result);
        newTab.document.close();
      };

      reader.readAsText(blob);
    },
    /* Router */
    goToTemplates() {
      this.$router.push('/templates');
    },
  },
};
</script>

<style lang="scss" scoped>
/* Custom Buttons */
.btnx {
  border-radius: 5px 0px 0px 5px;
  height: 42px !important;
  margin-left: 10px !important;
}

.btn-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0px 5px 5px 0px;
  height: 42px !important;
}
</style>

<style lang="scss">
/* Custom Menu */
#custom-dropdown-menu a.vs-dropdown--item-link {
  padding: 0 !important;
}
</style>
