function toggleFormatTextbox(value, maxWidth) {
  // Get parent element
  const selected = document.querySelector('.element-selected');

  if (!selected) return;

  // Manipulate the DOM
  const appendButtons = (parent, buttons) => {
    buttons.forEach((node) => parent.appendChild(node));
  };

  const destroyButtons = (buttons) => {
    buttons.forEach((node) => {
      const target = document.getElementById(node.id);

      if (target) target.parentNode.removeChild(target);
    });
  };

  // Resize buttons
  const addResizeEvent = (selected, button) => {
    const side = button.id.includes('right') ? 'right' : 'left';
    let startX, startWidth;

    const resize = (e) => {
      let width = side === 'right' ?
        startWidth + e.clientX - startX :
        startWidth - e.clientX + startX;

      selected.style.width = Math.min(width, maxWidth) + 'px';
    };

    const stopResize = () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResize);
      destroyButtons(buttons);
      selected.classList.remove('formatting');
    };

    // Event listener for mouse down on the resize button
    button.addEventListener('mousedown', (e) => {
      e.preventDefault();

      startX = e.clientX;
      startWidth = parseInt(document.defaultView.getComputedStyle(selected).width, 10);

      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', stopResize);
    });
  };

  const createAndConfigureButton = (buttonId) => {
    const btn = document.createElement('button');
    btn.id = buttonId;
    btn.classList.add('resize-btn');
    return btn;
  };

  // Create buttons
  const props = [
    {
      id: 'resize-left-bottom-btn',
      bottom: '-8px',
      left: '-8px',
      cursor: 'nesw-resize',
    },
    {
      id: 'resize-left-top-btn',
      top: '-8px',
      left: '-8px',
      cursor: 'nwse-resize',
    },
    {
      id: 'resize-right-bottom-btn',
      bottom: '-8px',
      right: '-8px',
      cursor: 'nwse-resize',
    },
    {
      id: 'resize-right-top-btn',
      top: '-8px',
      right: '-8px',
      cursor: 'nesw-resize',
    },
  ];

  const buttons = props.map(({ id, top, bottom, left, right, cursor }) => {
    const resizeBtn = createAndConfigureButton(id);

    if (top) resizeBtn.style.top = top;
    if (bottom) resizeBtn.style.bottom = bottom;
    if (left) resizeBtn.style.left = left;
    if (right) resizeBtn.style.right = right;

    resizeBtn.style.cursor = cursor;

    addResizeEvent(selected, resizeBtn);

    return resizeBtn;
  });

  if (value) {
    selected.classList.add('formatting');
    appendButtons(selected, buttons);
  } else {
    destroyButtons(buttons);

    // Remove formatting class from elements
    const elements = document.querySelectorAll('.formatting');
    elements.forEach((e) => e.classList.remove('formatting'));
  }
}

export default toggleFormatTextbox;
