/* Format Text Nodes */
const formatTextNodes = (formatElement) => {
  const element = document.querySelector('.element-selected');

  const getTextNodes = function(node) {
    const textNodes = [];
    const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, null, false);

    let currentNode;
    while ((currentNode = walker.nextNode())) {
      textNodes.push(currentNode);
    }

    return textNodes;
  };

  const textNodes = getTextNodes(element);

  textNodes.forEach((textNode) => {
    const formatTag = document.createElement(formatElement);
    const parent = textNode.parentNode;
    parent.insertBefore(formatTag, textNode);
    formatTag.appendChild(textNode);
  });
};

const clearTextNodesFormatting = (formatElement) => {
  const element = document.querySelector('.element-selected');

  const updated = element.innerHTML
    .replaceAll(`<${formatElement}>`, '')
    .replaceAll(`</${formatElement}>`, '');

  element.innerHTML = updated;
};

/* Format Selected Text */
const formatSelection = (range, formatElement) => {
  const formatTag = document.createElement(formatElement);
  range.surroundContents(formatTag);
};

const clearSelectionFormatting = (range, formatElement) => {
  const formatTags = range.commonAncestorContainer.querySelectorAll(formatElement);

  // Iterate through each tag within the range and replace it with its content
  formatTags.forEach((formatTag) => {
    const parent = formatTag.parentNode;
    while (formatTag.firstChild) {
      parent.insertBefore(formatTag.firstChild, formatTag);
    }
    parent.removeChild(formatTag);
  });
};

export {
  formatTextNodes,
  clearTextNodesFormatting,
  formatSelection,
  clearSelectionFormatting,
};
