<template>
  <aside class="elements-library-wrapper">
    <vs-card class="elements-library">
      <header
        slot="header"
        class="elements-library__header"
      >
        <h5>Pesquisar</h5>

        <!-- Filters -->
        <FilterInput
          :value="filter"
          @set-value="(val) => filter = val"
        />
      </header>

      <div class="elements-library__content">
        <vs-collapse accordion>
          <vs-collapse-item>
            <div slot="header">
              <i class="float-left mdi mdi-view-column-outline mr-2 c-header-icon" />

              Colunas
            </div>

            <ul class="elements-library__el-list">
              <li
                v-for="layout in layouts"
                :key="layout.id"
                class="elements-library__el-list--layout"
                draggable="true"
                @dragstart="(e) => handleDragStart(e, layout)"
                @dragend="handleDragEnd"
              >
                <div
                  v-for="(cell, index) in layout.cells"
                  :key="`${cell}-${index}`"
                  :class="
                    cell.colspan > 1 ?
                      'layout-col--span-2' :
                      'layout-col'
                  "
                />
              </li>
            </ul>
          </vs-collapse-item>

          <vs-collapse-item>
            <div slot="header">
              <i class="float-left mdi mdi-shape-outline mr-2 c-header-icon" />

              Blocos
            </div>

            <ul class="elements-library__el-list">
              <li
                v-for="block in filteredBlocks"
                :key="block.id"
                class="elements-library__el-list--block"
                draggable="true"
                @dragstart="(e) => handleDragStart(e, block)"
                @dragend="handleDragEnd"
              >
                <div class="block-content">
                  <img
                    :src="require(`./assets/${block.illustration}`)"
                    :alt="block.id"
                    style="height:80px;max-width:100%;max-height:100%;"
                  >
                </div>

                <div class="block-tags">
                  <vs-chip
                    v-for="tag in block.tags"
                    :key="tag"
                    class="c-vs-chip"
                    color="#327e88"
                    transparent
                  >
                    {{ tag }}
                  </vs-chip>
                </div>
              </li>
            </ul>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vs-card>
  </aside>
</template>

<script>
/* Components */
import FilterInput from './FilterInput.vue';

/* Config */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;

/* Data */
import getBlocks from './data/blocks';
import layouts from './data/layouts';

/* Style */
import './scss/element-library.scss';

export default {
  name: 'ElementLibrary',
  components: {
    FilterInput,
  },
  data: () => ({
    filter: [],
  }),
  computed: {
    blocks() {
      const imageSlotUrl = `${configDomain}/images/template-builder/image-slot.png`;
      const logo = this.$store.state.emailTemplateLogo;
      const btnColor = this.$store.state.configBox.bgCardColors;
      const logoWidth = this.$store.state.configBox.logoWidth;
      const ownerCopyright = this.$store.state.ownerCopyright;

      const tokens = {
        btnColor,
        imageSlotUrl,
        logo,
        logoWidth,
        ownerCopyright,
      };

      return getBlocks(tokens);
    },
    filteredBlocks() {
      const visibleBlocks = this.blocks.filter((b) => !b.hidden);

      if (this.filter.length > 0) {
        let filtered = [...visibleBlocks];

        filtered = filtered.filter((block) => {
          return this.filter.every((item) => block.tags.includes(item));
        });

        return filtered;
      }

      return visibleBlocks;
    },
    layouts() {
      return layouts;
    },
  },
  methods: {
    /* Event Handlers */
    handleDragStart(e, element) {
      e.target.classList.add('dragging');
      e.dataTransfer.setData('droppable-element', JSON.stringify(element));
    },
    handleDragEnd(e) {
      e.preventDefault();
      e.target.classList.remove('dragging');

      // Remove droppable area style
      const dropCols = document.querySelectorAll('.template-window__col');
      const dropCols2 = document.querySelectorAll('.template-window__col--span-2');
      const dropRows = document.querySelectorAll('.template-window__row');

      const dropAreas = [...dropCols, ...dropCols2, ...dropRows];

      dropAreas.forEach((el) => el.classList.remove('droppable-area'));
    },
  },
};
</script>
