// Helpers
import rgbToHex from '@/helpers/rgb-to-hex';

/* Detect component */
const isButton = (target) => {
  if (target.tagName !== 'A') return false;
  const children = target.getElementsByTagName('SPAN');
  return children.length > 0;
};

const isClickableImage = (target) => {
  if (target.tagName !== 'IMG') return false;
  const parentElement = target.parentElement;
  return parentElement.tagName === 'A';
};

/* Get styles */
const getInlineCSSRules = (target) => {
  const inlineCSSRules = [];

  if (target.hasAttribute('style')) {
    const styleAttrValue = target.getAttribute('style');
    const styleProperties = styleAttrValue.split(';');

    styleProperties.forEach((property) => {
      const rule = property.split(':')[0].trim();
      if (rule !== '') inlineCSSRules.push(rule);
    });
  }

  return inlineCSSRules;
};

const setupToolbar = (target) => {
  const allowFormatTags = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'A', 'STRONG', 'EM'];

  let toolbar = [];
  let inputs = {};

  const rules = getInlineCSSRules(target);

  // Add attribute controls (href, src)
  if (isClickableImage(target)) {
    const link = target.parentElement.getAttribute('data-href');

    inputs.href = link;
    inputs.imageWidth = target.style.width;
    inputs.src = target.getAttribute('src');

    toolbar.push({ id: 'image', type: 'control', icon: 'image' });
    toolbar.push({ id: 'img-link', type: 'control', icon: 'link' });
  } else {
    if (target.tagName === 'A') {
      inputs.href = target.getAttribute('data-href');

      toolbar.push({ id: 'link', type: 'control', icon: 'link' });
    }

    if (target.tagName === 'IMG') {
      inputs.imageWidth = target.style.width;
      inputs.src = target.getAttribute('src');

      toolbar.push({ id: 'image', type: 'control', icon: 'image' });
    }
  }

  // Add font options (font-family, font-size)
  if (allowFormatTags.includes(target.tagName)) {
    inputs.fontFamily = target.style.fontFamily;
    inputs.fontSize = target.style.fontSize;
    inputs.lineHeight = target.style.lineHeight;

    toolbar.push({ id: 'font-options' });
  }

  if (rules.includes('background-color')) {
    inputs.bgColor = rgbToHex(target.style.backgroundColor);

    toolbar.push({
      id: 'background-color',
      type: 'control',
      icon: 'format_color_fill',
    });
  }

  if (rules.includes('color')) {
    inputs.textColor = rgbToHex(target.style.color);

    toolbar.push({
      id: 'color',
      type: 'control',
      icon: 'format_color_text',
    });
  }

  // Add switch buttons (bold: <strong>, italic: <em>)
  if (allowFormatTags.includes(target.tagName)) {
    toolbar.push({
      id: 'format-bold',
      type: 'switch',
      value: 'bold',
      icon: 'format_bold',
    });

    toolbar.push({
      id: 'format-italic',
      type: 'switch',
      value: 'italic',
      icon: 'format_italic',
    });
  }

  // Add toggle buttons (text-align)
  if (rules.includes('text-align') && target.tagName !== 'A') {
    inputs.textAlign = target.style.textAlign;

    toolbar.push({
      id: 'text-align',
      type: 'toggle',
      property: 'textAlign',
      values: [
        { icon: 'format_align_left', value: 'left' },
        { icon: 'format_align_center', value: 'center' },
        { icon: 'format_align_right', value: 'right' },
      ],
    });
  }

  if (isButton(target)) {
    inputs.width = target.style.width;

    toolbar.push({
      id: 'button-width',
      type: 'toggle',
      property: 'width',
      values: [
        { icon: 'straighten', value: '160px' },
        { icon: 'straighten', value: '216px' },
        { icon: 'straighten', value: '100%' },
      ],
    });
  }

  return { toolbar, inputs };
};

export default setupToolbar;
