<!-- eslint-disable vue/no-v-html -->
<template>
  <vs-card class="template-canvas">
    <!-- Add Row Top -->
    <div
      v-if="rows.length <= maxRowsAllowed"
      class="template-canvas__btn btn-top"
      @click="() => addRow('top')"
      @dragleave="handleAddRowLeave"
      @dragover="handleAddRowEnter"
      @drop="(e) => handleAddRowDrop(e, 'top')"
    >
      <span class="arrow material-icons-outlined">
        arrow_upward
      </span>

      <span class="text">
        Adicionar Seção
      </span>
    </div>

    <!-- Canvas -->
    <table
      id="template-canvas-table"
      cellpadding="0"
      cellspacing="0"
      align="center"
      border="0"
      style="max-width: 600px; width: 100%;"
    >
      <!-- Rows -->
      <draggable :list="rows">
        <tr
          v-for="row in rows"
          :key="row.id"
          class="template-canvas__row"
          @dragleave="handleDragLeave"
          @dragover="handleDragOver"
          @drop="(e) => handleDropLayout(e, row.id)"
        >
          <!-- Data -->
          <td
            v-for="cell in row.cells"
            :key="cell.id"
            align="center"
            :colspan="getLayout(row, cell).colspan"
            :style="getLayout(row, cell).style"
          >
            <table
              cellpadding="0"
              cellspacing="0"
              border="0"
              width="100%"
              :style="`min-width: 100px; width: 100%; min-height: ${cell.minHeight || '90px'}; height: 100%;`"
            >
              <tr>
                <td
                  class="template-canvas__col"
                  align="center"
                  :style="`${cell.style} padding: ${cell.cellPadding};`"
                  @click="showTooltip"
                  @drop="(e) => handleDropBlock(e, row.id, cell.id)"
                  v-html="cell.content"
                />
              </tr>
            </table>
          </td>

          <td class="template-canvas__row-delete">
            <vs-button
              v-if="rows.length > 1 && !row.fixed"
              class="template-canvas__row-delete"
              color="danger"
              icon="delete"
              size="small"
              @click="() => deleteRow(row.id)"
            />
          </td>
        </tr>
      </draggable>
    </table>

    <!-- Add Row Bottom -->
    <div
      v-if="rows.length <= maxRowsAllowed"
      class="template-canvas__btn btn-bottom"
      @click="() => addRow('bottom')"
      @dragleave="handleAddRowLeave"
      @dragover="handleAddRowEnter"
      @drop="(e) => handleAddRowDrop(e, 'bottom')"
    >
      <span class="arrow material-icons-outlined">
        arrow_downward
      </span>

      <span class="text">Adicionar Seção</span>
    </div>

    <!-- Edit Element -->
    <TooltipToolbar
      :control="control"
      :inputs="inputs"
      :toolbar="toolbar"
      :tooltip-visible="tooltipVisible"
      :tooltip-position="tooltipPosition"
      @hide-tooltip="hideTooltip"
      @set-control="(val) => control = val"
      @update-inputs="(val) => inputs = val"
    />
  </vs-card>
</template>

<script>
/* Components */
import draggable from 'vuedraggable';
import TooltipToolbar from './TooltipToolbar.vue';

/* Composables */
import toggleFormatTextbox from './composables/toggle-format-textbox';

/* Data */
import getBlocks from './data/blocks';

/* Helpers */
const { v4: uuidv4 } = require('uuid');
import appendDataLocator from './helpers/append-data-locator';
import generateCellByBlockId from './helpers/generate-cell-by-block-id';
import getTableLayout from './helpers/get-table-layout';
import setupContentEditable from './helpers/setup-content-editable';
import setupToolbar from './helpers/setup-toolbar';
import translateBlockToHtml from './helpers/translate-block-to-html';

/* Services */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;

/* Style */
import './scss/template-canvas.scss';

export default {
  name: 'TemplateCanvas',
  components: {
    draggable,
    TooltipToolbar,
  },
  props: {
    selectedTemplate: {
      type: Object,
      required: true,
    },
    saveTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    /*
      Note: the cell's "content" property provides an initial HTML
      for the element in the editor, it does not change when the
      real element is edited. However, the real HTML is still
      linked to each cell through the "data-locator" attribute,
      which will assist in replacing "content" with the real HTML
      on save ~ "prepareTemplate" method in 'index.vue'.
    */
    rows: [],
    /* Tooltip */
    control: '',
    toolbar: [],
    tooltipPosition: { x: 0, y: 0 },
    tooltipVisible: false,
    inputs: {},
  }),
  computed: {
    blocks() {
      const imageSlotUrl = `${configDomain}/images/template-builder/image-slot.png`;
      const logo = this.$store.state.emailTemplateLogo;
      const btnColor = this.$store.state.configBox.bgCardColors;
      const logoWidth = this.$store.state.configBox.logoWidth;
      const ownerCopyright = this.$store.state.ownerCopyright;

      const tokens = {
        btnColor,
        imageSlotUrl,
        logo,
        logoWidth,
        ownerCopyright,
      };

      return getBlocks(tokens);
    },
    maxRowsAllowed() {
      return 20;
    },
    maxRowCols() {
      let max = 1;

      this.rows.forEach((r) => {
        if (r.cells.length > max) max = r.cells.length;
      });

      return max;
    },
    rowsColspanTwo() {
      return this.rows
        .filter((r) => {
          const cellSpansTwoCols = r.cells.find((c) => c.colspan === 2);
          if (cellSpansTwoCols) return r;
        })
        .map(({ id }) => id);
    },
    allowFormatTags() {
      return ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P'];
    },
    allowSelectTags() {
      return ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'A', 'IMG', 'SPAN'];
    },
  },
  watch: {
    saveTrigger(value) {
      if (value) this.$emit('save', this.rows);
    },
  },
  mounted() {
    // Open template
    console.log('selectedTemplate: ', this.selectedTemplate);
    if (Object.keys(this.selectedTemplate).length > 0) {
      if (this.selectedTemplate.content.length > 0) {
        this.rows = this.selectedTemplate.content;
      } else {
        alert('Houve um erro ao tentar carregar o template.');
      }
    } else {
      alert('Houve um erro ao tentar carregar o template.');
    }

    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      this.hideTooltip();
    });
  },
  methods: {
    /* Table */
    addRow(location) {
      // Create new blank cell
      const blankRowId = uuidv4();
      const blankCell = generateCellByBlockId(this.blocks, 'Vazio', 1);

      const blankRow = {
        id: blankRowId,
        cells: [blankCell],
      };

      // Add blank cell and update rows
      let rows = this.rows.slice();

      const deletableRows = rows.filter((row) => {
        return row.id !== blankRowId  && !row.fixed;
      });

      const fixedRows = rows.filter((row) => {
        return 'fixed' in row;
      });

      if (fixedRows.length === 2) {
        if (location === 'top') {
          const updated = [fixedRows[0], blankRow, ...deletableRows, fixedRows[1]];
          this.rows = updated;
        } else {
          const updated = [fixedRows[0], ...deletableRows, blankRow, fixedRows[1]];
          this.rows = updated;
        }
      }

      this.hideTooltip();

      return { rowId: blankRowId, cellId: blankCell.id };
    },
    deleteRow(rowId) {
      let updated = [...this.rows];
      this.rows = updated.filter((row) => row.id !== rowId);
      this.hideTooltip();
    },
    getLayout(row, cell) {
      return getTableLayout(
        this.maxRowCols,
        this.rowsColspanTwo,
        row,
        cell,
      );
    },
    /* Tooltip Toolbar */
    hideTooltip() {
      this.toggleFormatTextbox(false, 600);

      this.tooltipVisible = false;
      this.toolbar = [];
      this.inputs = {};
      this.control = '';

      // Remove selected class from elements
      const elements = document.querySelectorAll('.element-selected');
      elements.forEach((e) => e.classList.remove('element-selected'));
    },
    showTooltip(e) {
      const target = e.target;
      const hasSelectedClass = target.classList.contains('element-selected');

      if (!hasSelectedClass) {
        this.hideTooltip();

        /*
          The base cell is the <td> directly above the block's content.
          This condition prevents the user from selecting or editing a <td>
          used for spacing purposes.
        */
        const isBaseCell = target.classList.contains('template-canvas__col');

        if (this.allowSelectTags.includes(target.tagName) || isBaseCell) {
          // Add selected element style
          target.classList.add('element-selected');
          this.updateTooltipPosition();

          // Setup toolbar inputs/controls based on selected element
          this.setupContentEditable(target);
          this.setupToolbar(target);

          if (this.allowFormatTags.includes(target.tagName)) {
            this.setupFormatTextbox(target);
          } else {
            this.toggleFormatTextbox(false, 600);
          }
        }
      }
    },
    /* Canvas Events */
    handleDragOver(e) {
      e.preventDefault();

      const isBaseCell = e.target.classList.contains('template-canvas__col');

      if (isBaseCell) {
        e.target.classList.add('element-selected');
      } else {
        const closest = e.target.closest('.template-canvas__col');
        if (closest) closest.classList.add('element-selected');
      }
    },
    handleDragLeave(e) {
      e.preventDefault();

      const isBaseCell = e.target.classList.contains('template-canvas__col');

      if (isBaseCell) {
        e.target.classList.remove('element-selected');
      } else {
        const closest = e.target.closest('.template-canvas__col');
        if (closest) closest.classList.add('element-selected');
      }
    },
    handleDropBlock(e, rowId, cellId) {
      e.preventDefault();

      const data = e.dataTransfer.getData('droppable-element');

      if (data) {
        const element = JSON.parse(e.dataTransfer.getData('droppable-element'));

        if (element.type === 'block') {
          const rowIdx = this.rows.findIndex((r) => r.id === rowId);
          const cellIdx = this.rows[rowIdx].cells.findIndex((c) => c.id === cellId);

          // Update cell content
          let updated = [...this.rows];

          const html = translateBlockToHtml(element.template, element.props);

          const cell = updated[rowIdx].cells[cellIdx];

          cell.block = element;
          cell.content = appendDataLocator(html, cellId);
          cell.minHeight = element.colMinHeight;
          cell.cellPadding = element.cellPadding;

          this.rows = updated;

          this.hideTooltip();
        }
      }
    },
    handleAddRowEnter(e) {
      e.preventDefault();

      if (!e.target.classList.contains('drag-over')) {
        e.target.classList.add('drag-over');
      }
    },
    handleAddRowLeave(e) {
      e.preventDefault();
      e.target.classList.remove('drag-over');
    },
    handleAddRowDrop(e, location) {
      e.preventDefault();
      e.target.classList.remove('drag-over');
      const newRow = this.addRow(location);
      this.handleDropBlock(e, newRow.rowId, newRow.cellId);
    },
    handleDropLayout(e, rowId) {
      e.preventDefault();
      e.target.classList.remove('element-selected');

      const data = e.dataTransfer.getData('droppable-element');

      if (data) {
        const element = JSON.parse(e.dataTransfer
          .getData('droppable-element'));

        if (element.type === 'layout') {
          // Reset row cells
          const cells = element.cells.map((td) => {
            return generateCellByBlockId(this.blocks, 'Vazio', td.colspan);
          });

          const rowIdx = this.rows.findIndex((r) => r.id === rowId);

          // Update row cells
          let updated = [...this.rows];
          updated[rowIdx].cells = cells;
          this.rows = updated;

          this.hideTooltip();
        }
      }
    },
    updateTooltipPosition() {
      const element = document.querySelector('.element-selected');

      const rect = element.getBoundingClientRect();
      const tooltipHeight = 45;
      const scrollY = window.scrollY;

      const x = rect.left;
      const y = rect.top + scrollY - tooltipHeight;

      this.tooltipPosition.x = `${x}px`;
      this.tooltipPosition.y = `${y}px`;

      this.tooltipVisible = true;
    },
    /* Helpers */
    setupContentEditable(target) {
      setupContentEditable(target);
    },
    setupFormatTextbox(target) {
      // Set textbox max-width to the <td> container's size in pixels
      const container = target.parentElement;
      const containerWidth = parseInt(document.defaultView.getComputedStyle(container).width, 10);
      const containerPadding = parseInt(document.defaultView.getComputedStyle(container).padding, 10);
      const textboxMaxWidth = containerWidth - (containerPadding * 2);

      target.style.maxWidth = `${textboxMaxWidth}px`;

      this.toggleFormatTextbox(true, textboxMaxWidth);
    },
    setupToolbar(target) {
      const { toolbar, inputs } = setupToolbar(target);

      this.toolbar = toolbar;
      this.inputs = inputs;
    },
    toggleFormatTextbox(value, textboxMaxWidth) {
      toggleFormatTextbox(value, textboxMaxWidth);
    },
  },
};
</script>
