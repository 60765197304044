<template>
  <div class="color-picker-container">
    <!-- Primary Color -->
    <button
      class="color-input-box"
      :style="{ backgroundColor: colorPrimary }"
      @click="updateInputs(styleProperty, colorPrimary);"
    />

    <!-- Secondary Color -->
    <button
      class="color-input-box"
      :style="{ backgroundColor: colorSecondary }"
      @click="updateInputs(styleProperty, colorSecondary);"
    />

    <!-- Contrast White -->
    <button
      class="color-input-box"
      :style="{ backgroundColor: '#ffffff' }"
      @click="updateInputs(styleProperty, '#ffffff');"
    />

    <!-- Contrast Black -->
    <button
      class="color-input-box"
      :style="{ backgroundColor: '#1a1a1a' }"
      @click="updateInputs(styleProperty, '#1a1a1a');"
    />

    <input
      id="template--color-input-picker"
      v-model="colorModel"
      class="color-input-picker"
      type="color"
    >
  </div>
</template>

<script>
export default {
  name: 'ColorPicker',
  props: {
    value: {
      type: String,
      required: true,
    },
    styleProperty: {
      type: String,
      required: true,
    },
  },
  computed: {
    /* Style */
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    /* Models */
    colorModel: {
      get() { return this.value },
      set(value) { this.updateInputs(this.styleProperty, value) },
    },
  },
  methods: {
    updateInputs(styleProperty, value) {
      const colorInput = document.getElementById('template--color-input-picker');

      colorInput.value = value;

      const event = new Event('change');
      colorInput.dispatchEvent(event);

      this.$emit('update-inputs', styleProperty, value);
    },
  },
};
</script>

<style lang="scss">
.color-picker-container {
  background-color: rgb(243, 243, 243);
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-flow: row wrap;
}

.color-input-box,
.color-input-picker {
  border: 0;
  border-radius: 2px;
  height: 24px;
  margin: 2px;
  width: 24px;
}
</style>
