const getTableLayout = (
  maxRowCols,
  rowsColspanTwo,
  row,
  cell,
) => {
  const colsLength = row.cells.length;
  const colspanSymbol = cell.colspan;

  let colspan = '12';
  let width = '100%';

  if (maxRowCols === 2) {
    if (rowsColspanTwo.includes(row.id)) {
      if (colspanSymbol === 1) {
        colspan = '2';
        width = '33.3%';
      } else if (colspanSymbol === 2) {
        colspan = '4';
        width = '66.6%';
      }
    } else if (rowsColspanTwo.length > 0) {
      if (colsLength === 2) {
        colspan = '3';
        width = '50%';
      } else if (colsLength === 1) {
        colspan = '6';
        width = '100%';
      }
    } else if (colsLength === 2) {
      colspan = '6';
      width = '50%';
    }
  } else if (maxRowCols === 3) {
    if (rowsColspanTwo.includes(row.id)) {
      if (colspanSymbol === 1) {
        colspan = '2';
        width = '33.3%';
      } else if (colspanSymbol === 2) {
        colspan = '4';
        width = '66.6%';
      }
    } else if (rowsColspanTwo.length > 0) {
      if (colsLength === 3) {
        colspan = '2';
        width = '33.3%';
      } else if (colsLength === 2) {
        colspan = '3';
        width = '50%';
      }
    } else if (colsLength === 3) {
      colspan = '2';
      width = '3.33%';
    } else if (colsLength === 2) {
      colspan = '3';
      width = '50%';
    }
  }

  return {
    colspan,
    style: `width:${width};vertical-align: top;`,
  };
};

export default getTableLayout;
