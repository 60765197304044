export default [
  {
    id: 'layout_1',
    type: 'layout',
    cells: [
      {
        colspan: 1,
      },
    ],
  },
  {
    id: 'layout_2',
    type: 'layout',
    cells: [
      {
        colspan: 1,
      },
      {
        colspan: 1,
      },
    ],
  },
  {
    id: 'layout_3',
    type: 'layout',
    cells: [
      {
        colspan: 1,
      },
      {
        colspan: 2,
      },
    ],
  },
  {
    id: 'layout_4',
    type: 'layout',
    cells: [
      {
        colspan: 2,
      },
      {
        colspan: 1,
      },
    ],
  },
];
