/** Source
 * 1: https://www.youtube.com/watch?v=_G5OuTmuU0Q
 * 2: https://www.youtube.com/watch?v=AKdoVCzMvPM
*/
const generateHtmlFile = (tableInnerContent, returnStr) => {
  // Table
  const tableProps = 'cellpadding="0" cellspacing="0" align="center" border="0" width="100%"';
  const tableStyle = 'background-color: #FFFFFF; margin: 0 auto; width: 100%; max-width: 600px; border-spacing: 0';
  const table = `<table ${tableProps} style="${tableStyle};">${tableInnerContent}</table>`;

  // Document
  const DOCTYPE = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">';
  const htmlOpen = '<html xmlns="http://www.w3.org/1999/xhtml" lang="en-US" xml:lang="en-US">';
  const htmlClose = '</html>';

  // Head
  const metaContentType = '<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />';
  const metaIECompatible = '<meta http-equiv="X-UA-Compatible" content="IE=edge" />';
  const metaViewport = '<meta name="viewport" content="width=device-width, initial-scale=1.0">';
  const head = `<head>${metaContentType}${metaIECompatible}${metaViewport}</head>`;

  // Body
  const centerOpen = '<center style="width: 100%; table-layout: fixed; background-color: #cccccc; padding-bottom: 60px;">';
  const centerClose = '</center>';
  const body = `<body>${centerOpen}${table}${centerClose}</body>`;

  const result = `${DOCTYPE}${htmlOpen}${head}${body}${htmlClose}`;

  if (returnStr) return result;

  return new Blob([result], { type: 'text/html' });
};

export default generateHtmlFile;
