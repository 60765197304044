<template>
  <div>
    <vs-input
      v-model="searchText"
      class="elements-filter__input mt-3"
      icon="search"
      icon-after="true"
      placeholder="Pesquisar elementos..."
    />

    <!-- Search Options -->
    <ul
      v-show="searchOptionsVisible"
      class="elements-filter__options-list"
    >
      <li
        v-for="option in filteredOptions"
        :key="option"
      >
        <button
          class="elements-filter__options-list--option"
          @click="() => addOption(option)"
        >
          <span>{{ option }}</span>
        </button>
      </li>
    </ul>

    <!-- Added -->
    <div class="d-flex align-items-center mt-2">
      <ul class="elements-filter__list">
        <li
          v-for="chip in value"
          :key="chip"
        >
          <vs-chip
            closable
            color="#1A5CFF"
            transparent
            @click="removeOption(chip)"
          >
            {{ chip }}
          </vs-chip>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterInput',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    searchText: '',
    options: [
      'Botão',
      'Imagem',
      'Legenda',
      'Logo',
      'Parágrafo',
      'Título',
      'Mídia Social',
      'Menu',
      'Cabeçalho',
      'Rodapé',
    ],
  }),
  computed: {
    filteredOptions() {
      let updated = [...this.options];

      updated = updated.filter((option) => {
        if (this.searchText === '') {
          return true;
        } else {
          const normalizedOption = option.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          const normalizedSearchText = this.searchText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          return normalizedOption.toLowerCase().startsWith(normalizedSearchText.toLowerCase());
        }
      });

      return updated;
    },
    searchOptionsVisible() {
      return (
        this.searchText.length > 0 &&
        this.filteredOptions.length > 0
      );
    },
  },
  watch: {
    searchOptionsVisible(value) {
      const element = document.querySelector('.elements-filter__options-list');

      if (value) {
        element.classList.add('slide-from-top');
      } else {
        element.classList.remove('slide-from-top');
      }
    },
  },
  methods: {
    addOption(option) {
      if (!this.value.includes(option)) {
        let updated = [...this.value];
        updated.push(option);
        this.setValue(updated);

        this.searchText = '';
      }
    },
    removeOption(option) {
      if (this.value.includes(option)) {
        let updated = [...this.value];
        updated = updated.filter((el) => el !== option);
        this.setValue(updated);
      }
    },
    setValue(value) {
      this.$emit('set-value', value);
    },
  },
};
</script>

<style lang="scss">
.elements-filter__input {
  z-index: 9;
}

.elements-filter__options-list {
  background-color: #fefefe;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  list-style: none;
  margin-right: 1.25rem;

  .elements-filter__options-list--option {
    color: #343434;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    padding: 7px 0px 7px 10px;
    text-align: left;
    transition: 0.30s;
    width: 100%;

    &:hover {
      color: #1A5CFF;
      transform: translateX(4px);
    }
  }
}

.elements-filter__list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin-left: 0.5rem;

  li {
    margin: 0;
  }
}

.slide-from-top {
  animation: slideFromTop 0.25s forwards;
  position: relative;
  top: -20px;
}

@keyframes slideFromTop {
  0% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}
</style>
